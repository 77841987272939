export enum EmailTemplateVisibility {
  Global = "global",
  Organizational = "organizational",
  Personal = "personal"
}

export enum EmailBrandingId {
  Default
}

export enum EmailAliasType {
  Primary = "primary",
  Alias = "alias",
  Whitelabel = "whitelabel"
}
